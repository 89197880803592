import { Redirect } from '@reach/router'
import { find } from 'lodash'
import React from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { color, space, width } from 'styled-system'

import Info from './lib/info'
import { ReactComponent as Logo } from './lib/logo.svg'
import config from '../.lyzer/config.json'
import { useStorage } from '../use-storage'
import A from '../ui/a'

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  ${color}
`
Container.defaultProps = { bg: 'green' }

const Wrapper = styled.div`
  margin: 0 auto;
  padding-top: 16px;
  width: 768px;
`

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  ${width}
  ${space}
`

const Box = styled.div`
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  min-height: calc(100vh - 193px);
  ${color}
  ${space}
`
Box.defaultProps = { p: 4, bg: 'white' }

const Title = styled.div`
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  color: white;
  ${space}
`

const Subtitle = styled.div`
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  ${space}
`

const Text = styled.div`
  font-size: 14px;
  width: 493px;
  ${space}
`

const Circle = styled.div`
  width: 165px;
  height: 165px;
  border-radius: 165px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`

const Span = styled.span`
  font-weight: bold;
  ${color}
`

const Big = styled.span`
  font-weight: bold;
  font-size: 36px;
  text-align: center;
  display: block;
  width: 550px;
  margin: 0 auto;
  margin-bottom: 16px;
  ${color}
`

const OuterBox = styled.div`
  width: 40px;
  height: 40px;
  margin: 0 auto;
  margin-bottom: 8px;
  background: ${p => p.theme.colors.green};
  position: relative;
`

const InnerBox = styled.div`
  position: relative;
  top: 5px;
  left: 5px;
  width: 30px;
  height: 30px;
  background: ${p => (p.complete ? p.theme.colors.green : 'white')};
  border: 3px solid white;
`

const StepWrapper = styled.div`
  font-size: 14px;
  text-align: center;
  height: 100px;
  width: 100px;
`

const Step = ({ title1, title2, date }) => (
  <StepWrapper>
    <OuterBox>
      <InnerBox complete={date} />
    </OuterBox>
    <div>
      <div>{date ? title2 : title1}</div>
      {date && <div>{new Date(date).toLocaleDateString()}</div>}
    </div>
  </StepWrapper>
)

const status = {
  submitted: {
    title: 'Submitted',
    Content: () => (
      <Text py={3} mx='auto'>
        Your form has been submitted.
      </Text>
    )
  },
  review: {
    title: 'In Review',
    Content: () => (
      <Text py={3} mx='auto'>
        Your submission is currently being reviewed by representatives from
        Salem City. This step typically takes 5 - 8 business days. We thank you
        for your patience.
      </Text>
    )
  },
  approved: {
    title: 'Approved',
    Content: ({ doc }) => (
      <>
        <Big>
          Approved for Council Appearance on{' '}
          {moment(doc.appearanceDate).format('M/D/YYYY')} at 7:00pm
        </Big>
        <Text py={3} mx='auto'>
          Your submission is finished being reviewed and has been approved! You
          are scheduled to appear before the city council on{' '}
          <Span color='green'>
            {moment(doc.appearanceDate).format('M/D/YYYY')}
          </Span>
          . You will receive an email with more details.
          <A href='#' mt={4} mb={3}>
            Council Presentation Tips and Tricks
          </A>
          <A href='#'>Directions to Salem City Council</A>
        </Text>
      </>
    )
  }
}

const SVG = styled.svg`
  position: relative;
  display: block;
  top: 95px;
  margin: 0 auto;
  width: 400px;
  height: 20px;
`

const Line = styled.line`
  stroke: ${p => p.theme.colors.green};
  stroke-width: 7px;
  stroke-dasharray: 20px;
  stroke-dashoffset: 9px;
`

export default ({ id, docid }) => {
  const form = find(config.forms, { id })
  if (!form) return <Redirect noThrow to='/forms' />
  const [storage] = useStorage(id)
  const doc = find(storage, { id: docid })
  if (!doc) return <Redirect noThrow to='/forms' />
  const { title, Content } = status[doc.status]
  return (
    <Container>
      <Wrapper>
        <Flex>
          <Info color='white' />
          <Circle>
            <Logo />
          </Circle>
        </Flex>
        <Title mb={2}>{form.meta.name}</Title>
        <Box>
          <SVG>
            <Line x1={0} y1={10} x2={400} y2={10} />
          </SVG>
          <Line />
          <Flex width={493} py={5}>
            <Step
              date={doc.submittedOn}
              title1='Submit'
              title2='Submitted On'
            />
            <Step date={doc.seenOn} title1='In Review' title2='Reviewed On' />
            <Step date={doc.approvedOn} title1='Approve' title2='Approved On' />
          </Flex>
          {title !== 'Approved' && (
            <Subtitle pt={3} pb={4}>
              Current Status: {title}
            </Subtitle>
          )}{' '}
          <Content doc={doc} />
        </Box>
      </Wrapper>
    </Container>
  )
}
