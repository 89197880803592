import { chunk, map, min } from 'lodash'
import moment from 'moment'
import React, { useState } from 'react'
import styled from 'styled-components'
import { color, space } from 'styled-system'

const getGridTable = headers =>
  styled.table`
    display: grid;
    border-collapse: collapse;
    min-width: 100%;
    grid-template-columns: ${map(headers, h => `minmax(150px, 1fr)`).join(' ')};
  `

const Gthead = styled.thead`
  display: contents;
`

const Gtbody = styled.tbody`
  display: contents;
`

const Gtr = styled.tr`
  cursor: pointer;
  display: contents;
`

const Gth = styled.th`
  color: white;
  border: ${props => (props.isLast ? 0 : 'inherit')};
  font-size: 1.1rem;
  font-weight: normal;
  overflow: hidden;
  padding: 15px;
  position: sticky;
  text-align: left;
  text-overflow: ellipsis;
  top: 0;
  white-space: nowrap;
  ${color}
  ${space}
`

Gth.defaultProps = { p: 3, bg: 'green' }

const Gtd = styled.td`
  background: ${({ isEven }) => (isEven ? '#FFF' : '#ECECEC')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${space}
`

Gtd.defaultProps = { p: 3 }

const PagerWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row-reverse;
  width: 100%;
`

const PagerButton = styled.div`
  color: ${({ disabled, theme }) =>
    disabled ? theme.colors.lightgray : theme.colors.text}
  &:hover {
    background: ${({ disabled, theme }) =>
    disabled ? 'transparent' : theme.colors.lightgray};
  }
  &:active {
    background: ${({ disabled, theme }) =>
    disabled ? 'transparent' : theme.colors.darkgray};
  }
  border-radius: 5px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  font-weight: bold;
  user-select: none;
  ${space}
`

PagerButton.defaultProps = { mx: 1, px: 2, py: 2 }

export default function ({
  data,
  headers,
  onDatumClick,
  onHeaderClick,
  sortAsc,
  sortField
}) {
  const limit = 20
  const [page, setPage] = useState(0)
  const GridTable = getGridTable(headers)
  const getNextPage = () => {
    const nextPage = Math.min(page + 1, Math.floor(data.length / limit))
    setPage(nextPage)
  }
  const getPrevPage = () => {
    setPage(Math.max(page - 1, 0))
  }
  return (
    <>
      <GridTable>
        <Gthead>
          <Gtr>
            {map(headers, (h, i) => (
              <Gth
                key={i}
                isLast={i === headers.length - 1}
                onClick={() => onHeaderClick(h)}
              >
                {h.label} {sortField === h.formKey && (sortAsc ? '^' : 'v')}
              </Gth>
            ))}
          </Gtr>
        </Gthead>
        <Gtbody>
          {map(chunk(data, limit)[page], (d, i) => (
            <Gtr onClick={() => onDatumClick(d.id)} key={i}>
              {map(headers, (h, j) => (
                <Gtd key={j} isEven={i % 2 === 0}>
                  {h.type === 'date'
                    ? moment(d[h.formKey]).format('MM/DD/YYYY')
                    : d[h.formKey]}
                </Gtd>
              ))}
            </Gtr>
          ))}
        </Gtbody>
      </GridTable>
      <PagerWrapper>
        <PagerButton
          disabled={page === Math.floor(data.length / limit) || data.length < limit}
          onClick={() => getNextPage()}
        >
          {'>'}
        </PagerButton>
        <PagerButton disabled={page === 0} onClick={() => getPrevPage()}>
          {'<'}
        </PagerButton>
        <span>
          {limit * page + 1} - {min([data.length, limit * page + limit])} of{' '}
          {data.length}
        </span>
      </PagerWrapper>
    </>
  )
}
