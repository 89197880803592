import { Router, Link } from '@reach/router'
import React from 'react'
import ReactDOM from 'react-dom'
import { createGlobalStyle, ThemeProvider } from 'styled-components'

import theme from './theme'
import Admin from './admin'
import App from './user'
import SuperAdmin from './super-admin'

const Root = () => (
  <ul>
    <li>
      <Link to='forms'>User Page</Link>
    </li>
    <li>
      <Link to='admin'>Admin Page</Link>
    </li>
  </ul>
)

const NotFound = () => (
  <>
    <h1>uh oh, not found</h1>
    <Link to='/'>Run Home Jack!</Link>
  </>
)

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
  }

  * {
    font-family: Roboto, sans-serif;
    box-sizing: border-box;
  }
`

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <>
      <GlobalStyle />
      <Router>
        <Root path='/' />
        <App path='forms/*' />
        <Admin path='admin/*' />
        <SuperAdmin path='super-admin/*' />
        <NotFound default />
      </Router>
    </>
  </ThemeProvider>,
  document.getElementById('root')
)
