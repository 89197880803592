import { Redirect, Router } from '@reach/router'
import React from 'react'

import './admin.css'
import DocumentList from './document-list'
import Sidebar from './sidebar'

import config from '../.lyzer/config.json'

export default function AdminRouter () {
  return (
    <div className='admin-wrapper'>
      <Sidebar forms={config.forms} />
      <Router>
        <Redirect from='/' to={`admin/${config.forms[0].id}`} />
        <DocumentList path=':id/*' />
      </Router>
    </div>
  )
}
