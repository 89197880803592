import { isFunction } from 'lodash'
import { useState } from 'react'

export function useStorage (id) {
  const [data, setData] = useState([])
  const storeData = newData => {
    try {
      if (isFunction(newData)) newData = newData(data)
      const str = JSON.stringify(newData)
      window.localStorage.setItem(id, str)
      setData(newData)
    } catch {}
  }
  try {
    const raw = window.localStorage.getItem(id)
    const parsed = JSON.parse(raw) || []
    setData(parsed)
  } catch {}
  return [data, storeData]
}
