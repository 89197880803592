import { Redirect } from '@reach/router'
import axios from 'axios'
import { filter, find, forEach, get, map, set } from 'lodash'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { color, space } from 'styled-system'
import { useImmer } from 'use-immer'

import Info from './lib/info'
import { ReactComponent as Logo } from './lib/logo.svg'
import config from '../.lyzer/config.json'
import Formalyzer, { buildDefaultValue } from '../formalyzer'
import A from '../ui/a'
import Button from '../ui/button'
import { useStorage } from '../use-storage'

const Wrapper = styled.div`
  margin: 0 auto;
  margin-top: 32px;
  width: 768px;
`

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Box = styled.div`
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  min-height: calc(100vh - 193px);
  ${color}
  ${space}
`
Box.defaultProps = { p: 4, bg: 'lightgray' }

const Title = styled.div`
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  ${space}
`

const RightArea = styled.div`
  position: fixed;
  bottom: 16px;
  right: 16px;
  width: calc((100vw - 768px) / 2 - 32px);
`

const SubmitButton = styled(Button)`
  width: 100%;
`

const ErrorBox = styled.div`
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  bottom: 10px;
  ${space}

  &:before {
    content: '';
    width: 30px;
    height: 30px;
    background: white;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    display: block;
    position: absolute;
    bottom: -15px;
    left: 25%;
    transform: rotate(45deg);
  }
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 30px;
    background: white;
    position: absolute;
    bottom: 0;
    left: 0;
  }
`

const ErrorTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
`

function validate (value, gadgets) {
  const required = filter(gadgets, 'required')
  const defaults = buildDefaultValue(gadgets)
  const errors = []
  forEach(required, gadget => {
    if (gadget.formKey) {
      if (get(value, gadget.formKey) === get(defaults, gadget.formKey)) {
        errors.push(gadget)
      }
    }
  })
  return errors.length ? errors : null
}

export default ({ id, navigate }) => {
  const form = find(config.forms, { id })
  const [shouldValidate, setShouldValidate] = useState(false)
  const [errors, setErrors] = useState(null)
  const [, setStorage] = useStorage(id)
  if (!form) return <Redirect noThrow to='/forms' />
  const [value, updateValue] = useImmer(buildDefaultValue(form.gadgets))
  useEffect(() => {
    if (shouldValidate) setErrors(validate(value, form.gadgets))
  }, [form.gadgets, shouldValidate, value])
  return (
    <Wrapper>
      <Flex>
        <Info />
        <Logo />
      </Flex>
      <Title mt={2}>{form.meta.name}</Title>
      {form.meta.pdfLink && (
        <A my={1} href={form.meta.pdfLink}>
          Click here to download the pdf
        </A>
      )}
      <Box>
        <Formalyzer
          logic={form.logic}
          errorKeys={map(errors, 'formKey')}
          gadgets={form.gadgets}
          value={value}
          onChange={(key, val) => {
            updateValue(draft => {
              set(draft, key, val)
            })
          }}
        />
      </Box>
      <RightArea>
        {errors && (
          <ErrorBox px={4} py={3}>
            <ErrorTitle>
              Fix the following before re-submitting the form:
            </ErrorTitle>
            <ul>
              {errors.map(gadget => (
                <li>{gadget.label || gadget.formKey} is required.</li>
              ))}
            </ul>
          </ErrorBox>
        )}
        <SubmitButton
          disabled={errors}
          onClick={() => {
            const errors = validate(value, form.gadgets)
            if (errors) {
              setShouldValidate(true)
              setErrors(errors)
              return
            }
            setStorage(storage => [
              ...storage,
              { ...value, submittedOn: Date.now() }
            ])
            const host = window.location.origin
            axios.post(
              'https://young-ravine-93126.herokuapp.com/api/submit',
              {
                to: value.email,
                admin_to: form.meta.emails,
                admin_link: `${host}/admin/${id}/${value.id}`,
                progress_link: `${host}/forms/${id}/progress/${value.id}`
              },
              {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization:
                    'Bearer 47e0UPz7rk9pOSeEAd5E2Bt/OL0+r5l5XGhw2bXECfneWMysbjMC/bnk78YOnsJr'
                }
              }
            )
            navigate('confirmation')
          }}
        >
          Submit
        </SubmitButton>
      </RightArea>
    </Wrapper>
  )
}
