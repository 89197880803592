import { find, forEach, get, set } from 'lodash'
import React from 'react'
import uuid from 'uuid/v4'

import DefaultAssembler from './default-assembler'
import Gadgets from './gadgets'
import * as logicFns from './logic'

export default ({ logic, gadgets, value, onChange, errorKeys = [] }) => {
  if (!logic) return null
  const form = logicFns.mapLogic(logic, (alpha, children) => {
    const gadget = find(gadgets, { alpha })
    const Gadget = Gadgets[gadget.type] || Gadgets.Generic
    const Assembler = Gadget.Assembler || DefaultAssembler
    let fieldHandlers = {}
    if (gadget.formKey) {
      fieldHandlers.value = get(value, gadget.formKey)
      fieldHandlers.onChange = newValue => onChange(gadget.formKey, newValue)
    }
    const hasError = gadget.formKey && errorKeys.includes(gadget.formKey)
    return (
      <Assembler
        hasError={hasError}
        label={gadget.label}
        // description={gadget.description}
        component={
          <Gadget.Edit
            type={gadget.type}
            hasError={hasError}
            config={gadget.config}
            {...fieldHandlers}
            children={children}
          />
        }
      />
    )
  })
  return <div>{form}</div>
}

export const buildDefaultValue = gadgets => {
  const value = { id: uuid(), status: 'submitted' }
  forEach(gadgets, gadget => {
    if (gadget.formKey) {
      const Gadget = Gadgets[gadget.type] || Gadgets.Generic
      const initialValue = Gadget.initialValue ? Gadget.initialValue() : ''
      set(value, gadget.formKey, initialValue)
    }
  })
  return value
}
