import styled from 'styled-components'
import { color, space } from 'styled-system'

const Button = styled.button`
  height: 42px;
  font-size: 14px;
  border: none;
  cursor: pointer;
  user-select: none;
  ${color}
  ${space}
  &:hover {
    background: ${p => p.theme.colors.hoverGreen};
  }

  &:disabled {
    background: #c5cacf;
    color: #6a6a6a;
    cursor: not-allowed;
  }
`
Button.defaultProps = { color: 'white', bg: 'green' }

export default Button
