import faker from 'faker'
import { filter, get, map, range } from 'lodash'
import moment from 'moment'
import uuid from 'uuid/v4'

export default (numRecords, formConfig) => {
  return map(range(numRecords), i => {
    const gadgets = get(formConfig, 'gadgets')
    const dataGagets = filter(gadgets, g => g.formKey)
    const newRecord = {}
    for (const { formKey, type } of dataGagets) {
      switch (type) {
        case 'Text':
          if (formKey === 'name') newRecord[formKey] = faker.name.findName()
          else if (formKey === 'address') {
            newRecord[formKey] = faker.address.streetAddress()
          } else if (formKey === 'phone') {
            newRecord[formKey] = faker.phone.phoneNumber('(###)###-####')
          } else if (formKey === 'city') {
            newRecord[formKey] = faker.address.city()
          } else if (formKey === 'email') {
            newRecord[formKey] = faker.internet.email()
          } else if (formKey === 'zip') {
            newRecord[formKey] = faker.address.zipCode()
          } else newRecord[formKey] = faker.lorem.words(2)
          break
        case 'Date':
          newRecord[formKey] = moment(faker.date.recent(5)).format('YYYY-MM-DD')
          break
        case 'StateDropdown':
          newRecord[formKey] = faker.address.stateAbbr()
          break
        default:
          break
      }
    }
    newRecord.id = uuid()
    newRecord.status = Math.random() > 0.8 ? 'approved' : 'submitted'
    newRecord.submittedOn = faker.date.recent(5)
    if (newRecord.status === 'approved') {
      newRecord.appearanceDate = moment(faker.date.recent(5)).format(
        'YYYY-MM-DD'
      )
    }
    newRecord._isFakeData = true
    return newRecord
  })
}
