import React from 'react'
import styled from 'styled-components'
import { color, space } from 'styled-system'

const Container = styled.div`
  flex: 1;
  ${space}
`
Container.defaultProps = { p: 2 }

const Label = styled.label`
  display: block;
  font-weight: bold;
  font-size: 14px;
  margin-left: 21px;
  ${color}
`

export default ({ label, description, component, hasError }) => (
  <Container>
    {label && <Label color={hasError ? 'red' : 'darkgray'}>{label}</Label>}
    {component}
  </Container>
)
