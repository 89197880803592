import moment from 'moment'
import React, { useState } from 'react'
import styled from 'styled-components'
import { color, space } from 'styled-system'

const ModalWrapper = styled.div`
  background: #fff;
  margin: 50px auto;
  overflow-y: auto;
  width: 60%;
  z-index: 3;
`

const ShadowBox = styled.div`
  background: rgba(0, 0, 0, 0.7);
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  overflow-y: scroll;
  width: 100%;
  z-index: 2;
`

const ActionsHeader = styled.div`
  align-items: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-flow: row-reverse;
  height: 80px;
  ${color}
`
ActionsHeader.defaultProps = { bg: 'green' }

const ModalContent = styled.div`
  ${space}
  ${color}
`
ModalContent.defaultProps = { bg: 'lightgray', p: 4 }

const ActionButton = styled.div`
  &:active {
    transform: translate3d(2px, 2px, 0);
  }
  background: white;
  color: ${({ theme }) => theme.colors.green};
  cursor: pointer;
  user-select: none;
  ${space}
`
ActionButton.defaultProps = { mr: 3, p: 3 }

const CloseButton = styled.div`
  color: white;
  cursor: pointer;
  font-weight: bold;
  position: absolute;
  right: 175px;
  top: 50px;
`

const PopoverButton = styled.div`
  position: relative;
`

const ApprovePopover = styled.div`
  & input {
    margin: 16px 0;
  }
  background: white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  left: -50px;
  position: absolute;
  padding: 16px
  top: 72px;
  width: 256px;

  &:before {
    background: white;
    bottom: 90%;
    box-shadow: -1px -1px 1px rgba(0, 0, 0, 0.25);
    content: '';
    display: block;
    height: 30px;
    left: 50%;
    position: absolute;
    transform: rotate(45deg);
    width: 30px;
  }
`

// const EmailPopover = styled.div`
//   & input {
//     margin: 16px 0;
//   }

//   & input[type=textarea] {
//     height: 285px;
//   }
//   background: white;
//   box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
//   display: flex;
//   flex-direction: column;
//   height: 500px;
//   left: -19vh;
//   position: absolute;
//   padding: 16px
//   top: 72px;
//   width: 615px;

//   &:before {
//     background: white;
//     bottom: 97%;
//     box-shadow: -1px -1px 1px rgba(0, 0, 0, 0.25);
//     content: '';
//     display: block;
//     height: 30px;
//     left: 50%;
//     position: absolute;
//     transform: rotate(45deg);
//     width: 30px;
//   }
// `

const ApproveButton = styled.div`
  &:active {
    transform: translate3d(2px, 2px, 0);
  }
  align-items: center;
  align-self: end;
  color: white;
  cursor: pointer;
  display: flex;
  height: 42px;
  justify-content: center;
  user-select: none;
  width: 70px;
  ${color}
`
ApproveButton.defaultProps = { bg: 'green' }

const AppearanceDate = styled.div`
  color: white;
  font-weight: bold;
  margin-right: 16px;
`

export default ({
  approveRecord,
  record,
  children,
  onClickOutside,
  sendEmail
}) => {
  const [openAction, setOpenAction] = useState(null)
  const [date, setDate] = useState(null)
  // const [emailSubject, setEmailSubject] = useState(null)
  // const [emailBody, setEmailBody] = useState(null)
  return (
    <>
      <ShadowBox onClick={onClickOutside}>
        <CloseButton onClick={onClickOutside}>X Close</CloseButton>
        <ModalWrapper onClick={e => e.stopPropagation()}>
          <ActionsHeader>
            {!record.appearanceDate && (
              <PopoverButton>
                <ActionButton onClick={() => setOpenAction('approve')}>
                  Approve for Appearance
                </ActionButton>
                {openAction === 'approve' && (
                  <ApprovePopover type='approve'>
                    Pick a date
                    <input
                      onChange={e => setDate(e.target.value)}
                      type='date'
                      value={date}
                    />
                    <ApproveButton
                      onClick={() => {
                        setOpenAction(null)
                        approveRecord(date)
                      }}
                    >
                      Approve
                    </ApproveButton>
                  </ApprovePopover>
                )}
              </PopoverButton>
            )}
            {record.appearanceDate && (
              <AppearanceDate>
                Approved for appearance on{' '}
                {moment(record.appearanceDate).format('MM/DD/YYYY')}
              </AppearanceDate>
            )}
            {/* <PopoverButton>
              <ActionButton onClick={() => setOpenAction('changes')}>
                Send Email to Requestor
              </ActionButton>
              {openAction === 'changes' && (
                <EmailPopover type='changes'>
                  Subject
                  <input
                    onChange={e => setEmailSubject(e.target.value)}
                    type='text'
                    value={emailSubject}
                  />
                  Body
                  <input
                    onChange={e => setEmailBody(e.target.value)}
                    type='textarea'
                    value={emailBody}
                  />
                  <ApproveButton
                    onClick={() => {
                      setOpenAction(null)
                      sendEmail(emailSubject, emailBody)
                    }}
                  >
                    Send
                  </ApproveButton>
                </EmailPopover>
              )}
            </PopoverButton> */}
          </ActionsHeader>
          <ModalContent>{children}</ModalContent>
        </ModalWrapper>
      </ShadowBox>
    </>
  )
}
