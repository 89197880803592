import React from 'react'
import styled from 'styled-components'

const Textarea = styled.textarea`
  resize: vertical;
  width: 100%;
  min-height: 170px;
  padding: 8px;
  border: 1px solid #c5cacf;
`

export default React.forwardRef(({ onChange, ...rest }, ref) => (
  <Textarea
    ref={ref}
    onChange={onChange && (e => onChange(e.target.value))}
    {...rest}
  />
))
