import React from 'react'

import Text from '../../ui/text'

export const Edit = ({ config, value, onChange, hasError }) => (
  <Text
    placeholder={config.placeholder}
    value={value}
    onChange={onChange}
    hasError={hasError}
  />
)
