import { Router } from '@reach/router'
import React from 'react'

import Confirmation from './confirmation'
import Form from './form'
import List from './list'
import Progress from './progress'

export default () => (
  <Router>
    <List path='/' />
    <Form path=':id' />
    <Confirmation path=':id/confirmation' />
    <Progress path=':id/progress/:docid' />
  </Router>
)
