import axios from 'axios'
import {
  filter,
  find,
  get,
  isArray,
  isEmpty,
  map,
  reverse,
  sortBy
} from 'lodash'
import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Router } from '@reach/router'
import moment from 'moment'
import styled from 'styled-components'
import { space } from 'styled-system'
import { useImmer } from 'use-immer'

import config from '../.lyzer/config.json'

import DataTable from './data-table'
import Modal from './modal'
import Formalyzer from '../formalyzer'
import { useStorage } from '../use-storage'

const ScrollWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
  width: 100%;
`

const FormList = styled.div`
  height: 100%;
  width: 100%;
  ${space}
`
FormList.defaultProps = { p: 4 }

const SearchInput = styled.input`
  height: 42px;
  width: 35%;
  ${space}
`
SearchInput.defaultProps = { mb: 4, pl: 2 }

const SearchFieldDropdown = styled.select``

const DocumentView = ({ activeForm, closeView, documents, id }) => {
  const selectedDocument = find(documents, { id })
  const [, setData] = useStorage(activeForm.id)
  const [record, updateRecord] = useImmer(selectedDocument)
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    if (loaded) return
    setLoaded(true)
    updateRecord(draft => {
      if (!draft.seenOn) {
        draft.status = 'review'
        draft.seenOn = Date.now()
      }
    })
  }, [loaded, updateRecord])
  const approveRecord = date => {
    updateRecord(draft => {
      draft.status = 'approved'
      draft.appearanceDate = date
      draft.approvedOn = Date.now()
    })
    const host = window.location.origin
    axios.post(
      'https://young-ravine-93126.herokuapp.com/api/approve',
      {
        to: record.email,
        admin_link: `${host}/admin/${activeForm.id}/${record.id}`,
        progress_link: `${host}/forms/${activeForm.id}/progress/${record.id}`
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization:
            'Bearer 47e0UPz7rk9pOSeEAd5E2Bt/OL0+r5l5XGhw2bXECfneWMysbjMC/bnk78YOnsJr'
        }
      }
    )
  }
  useEffect(() => {
    if (record.status !== selectedDocument.status) {
      const newDocuments = map(documents, d => {
        if (d.id !== id) return d
        return record
      })
      setData(newDocuments)
    }
  }, [documents, id, record, selectedDocument.status, setData])
  const sendEmail = (subject, body) => {}
  return (
    <Modal
      record={record || {}}
      approveRecord={approveRecord}
      onClickOutside={closeView}
      sendEmail={sendEmail}
    >
      <Formalyzer
        gadgets={activeForm.gadgets}
        logic={activeForm.logic}
        onChange={() => {}}
        value={record}
      />
    </Modal>
  )
}

export default ({ navigate, location, id }) => {
  const activeForm = find(config.forms, { id: id }) || config.forms[0]
  const [searchTerm, setSearchTerm] = useState('')
  const [searchField, setSearchField] = useState('appearanceDate')
  const [sortField, setSortField] = useState(null)
  const [sortAsc, setSortAsc] = useState(true)
  const [data] = useStorage(activeForm.id)
  const getTableHeaders = form => {
    const withLabel = map(form.headers, h => find(form.gadgets, { formKey: h }))
    const extras = [
      { formKey: 'submittedOn', type: 'date', label: 'Submission Date' },
      { formKey: 'appearanceDate', type: 'date', label: 'Appearance Date' }
    ]
    return [...extras, ...withLabel]
  }
  const getSearchInputType = formKey => {
    return get(
      find(getTableHeaders(activeForm.headers), { formKey }),
      'type',
      'text'
    )
  }
  const setNewSort = field => {
    if (field.formKey === sortField) setSortAsc(!sortAsc)
    else {
      setSortField(field.formKey)
      setSortAsc(true)
    }
  }
  const sortedData = sortField
    ? sortBy(data, sortField)
    : sortBy(data, d => (d._isFakeData ? 1 : 0))
  const searchedData = filter(sortedData, d => {
    const val = get(d, searchField, '')
    const type = getSearchInputType(searchField)
    if (type === 'date') {
      if (!searchTerm[0] || !searchTerm[1] || !isArray(searchTerm)) {
        return true
      }
      if (isEmpty(val)) return false
      return moment(val).isBetween(searchTerm[0], searchTerm[1])
    } else {
      return val.includes(searchTerm)
    }
  })
  return (
    <>
      <Router>
        <DocumentView
          activeForm={activeForm}
          closeView={() => navigate('./')}
          documents={data}
          path=':id'
        />
      </Router>
      <ScrollWrapper>
        <FormList>
          {getSearchInputType(searchField) === 'date' ? (
            <>
              <DatePicker
                selected={searchTerm[0]}
                onChange={date => setSearchTerm([date, searchTerm[1]])}
              />
              <DatePicker
                selected={searchTerm[1]}
                onChange={date => setSearchTerm([searchTerm[0], date])}
              />
            </>
          ) : (
            <SearchInput
              type={getSearchInputType(searchField)}
              placeholder='Search'
              value={searchTerm}
              onChange={({ target: { value } }) => setSearchTerm(value)}
            />
          )}
          <SearchFieldDropdown
            value={searchField}
            onChange={e => {
              setSearchTerm(
                getSearchInputType(e.target.value) === 'date' ? [] : ''
              )
              setSearchField(e.target.value)
            }}
          >
            {map(sortBy(getTableHeaders(activeForm), 'label'), h => (
              <option key={h.formKey} value={h.formKey}>
                {h.label}
              </option>
            ))}
          </SearchFieldDropdown>
          <DataTable
            data={sortAsc ? searchedData : reverse(searchedData)}
            headers={getTableHeaders(activeForm)}
            onHeaderClick={setNewSort}
            onDatumClick={id => navigate(id)}
            sortField={sortField}
            sortAsc={sortAsc}
          />
        </FormList>
      </ScrollWrapper>
    </>
  )
}
