import { Link, Redirect } from '@reach/router'
import { find } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { color, space } from 'styled-system'

import Info from './lib/info'
import { ReactComponent as Logo } from './lib/logo.svg'
import A from '../ui/a'
import config from '../.lyzer/config.json'

const Wrapper = styled.div`
  margin: 0 auto;
  margin-top: 32px;
  width: 768px;
`

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Box = styled.div`
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  min-height: calc(100vh - 193px);
  ${color}
  ${space}
`
Box.defaultProps = { p: 4, bg: 'lightgray' }

const Title = styled.div`
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  ${space}
`

const Subtitle = styled.div`
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  ${space}
`

const Text = styled.div`
  font-size: 14px;
  width: 519px;
  ${space}
`

const RightA = styled(A)`
  text-align: right;
`

export default ({ id }) => {
  const form = find(config.forms, { id })
  if (!form) return <Redirect noThrow to='/forms' />
  return (
    <Wrapper>
      <Flex>
        <Info />
        <Logo />
      </Flex>
      <Title mt={2} mb={3}>
        {form.meta.name}
      </Title>
      <Box>
        <Subtitle pt={3} pb={4}>
          Thank you for your submission!
        </Subtitle>
        <Text py={3} mx='auto'>
          You should receive a confirmation email shortly containing a
          submission status link. Using that link, you can track the progress of
          your submission as it is reviewed by representatives from Salem City.
        </Text>
        <RightA pr={6} as={Link} to='/forms'>
          Return to Salem Forms
        </RightA>
      </Box>
    </Wrapper>
  )
}
