import { map } from 'lodash'
import { Link } from '@reach/router'
import React from 'react'
import styled from 'styled-components'

const SidebarLink = styled(Link)`
  display: block;
  text-decoration: none;
  font-size: 14px;
  line-height: 16px;
  padding: 20px;
  border-left: ${({ active, theme }) =>
    active ? `5px solid ${theme.colors.green}` : 'none'};
  padding-left: ${({ active }) => (active ? '15px' : '20px')};
  color: ${({ active, theme }) => (active ? theme.colors.green : 'inherit')};
`

export default function Sidebar ({ forms, onClick }) {
  return (
    <div className='sidebar-wrapper'>
      {map(forms, (form, i) => (
        <SidebarLink
          active={window.location.pathname.includes(form.id)}
          key={i}
          to={form.id}
        >
          {form.meta.name}
        </SidebarLink>
      ))}
    </div>
  )
}
