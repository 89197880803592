import { map } from 'lodash'
import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'

const Container = styled.div``
const DropContainer = styled.div`
  padding: 50px;
  color: #99a5ab;
  transition: all 200ms;
  border: 3px dashed #99a5ab;
  background: ${props => (props.isDragActive ? '#ccc' : '#e4e5e9')};
  font-size: 30px;
  text-align: center;
`

const File = styled.div`
  width: 140px;
  height: 170px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 8px;
  flex-shrink: 0;
`

const Main = styled.div`
  color: #c5cacf;
  opacity: 0.5;
  font-size: 48px;
  font-weight: bold;
  text-transform: uppercase;
`

const Sub = styled.div`
  position: absolute;
  bottom: 0;
  text-align: center;
  text-overflow: ellipsis;
  white-space: pre;
  width: 100%;
  overflow: hidden;
`

const last = name => {
  const parts = (name || '.').split('.') || []
  return parts.pop() || 'file'
}

const Flex = styled.div`
  display: flex;
  overflow: auto;
`

export const Edit = ({ value, onChange }) => {
  const { isDragActive, getRootProps, getInputProps } = useDropzone()

  const files = map(value, file => (
    <File>
      <Main>{last(file)}</Main>
      <Sub>{file}</Sub>
    </File>
  ))

  const onDrop = useCallback(
    e => {
      const names = map(e.dataTransfer.files, 'name')
      onChange([...(value || []), ...names])
    },
    [onChange, value]
  )
  return (
    <Container>
      <DropContainer {...getRootProps({ isDragActive, onDrop })}>
        <input {...getInputProps()} />
        {(value || []).length ? (
          <Flex>{files}</Flex>
        ) : (
          <p>Drag 'n' drop files here, or click to select files</p>
        )}
      </DropContainer>
    </Container>
  )
}
