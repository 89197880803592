import React from 'react'

import generateData from '../admin/fake-data'

import config from '../.lyzer/config.json'

export default function SuperAdmin () {
  return (
    <div>
      <button onClick={onSeedClick}>Seed</button>
      <button onClick={onClearClick}>Clear All Data</button>
    </div>
  )
}

function onClearClick () {
  window.localStorage.clear()
}

function onSeedClick () {
  config.forms.forEach(form => {
    const faksies = generateData(21, form)
    const existingData = getItem(form.id)
    setItem(form.id, existingData.concat(faksies))
  })
}

function getItem (key) {
  try {
    const raw = window.localStorage.getItem(key)
    return JSON.parse(raw) || []
  } catch {}
}

function setItem (key, value) {
  try {
    const raw = JSON.stringify(value)
    window.localStorage.setItem(key, raw)
  } catch {}
}
