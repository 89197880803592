import { Link } from '@reach/router'
import React from 'react'
import styled from 'styled-components'
import { color, space } from 'styled-system'
import { sortBy } from 'lodash'

import config from '../.lyzer/config.json'

const Wrapper = styled.div`
  width: 768px;
  margin: 0 auto;
`
const H1 = styled.h1`
  text-align: center;
`
const UL = styled.ul`
  list-style-type: none;
`
const LI = styled.li`
  margin: 16px 8px;
`
const A = styled(Link)`
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  height: 40px;
  text-decoration: none;
  ${color}
  ${space}
  &:hover {
    background: ${p => p.theme.colors.lightgray2};
  }
`
A.defaultProps = { pl: 3, bg: 'lightgray', color: 'blue' }

const sortedForms = sortBy(config.forms, form => form.meta.name)

export default () => (
  <Wrapper>
    <H1>Salem City Request Forms</H1>
    <UL>
      {sortedForms.map(form => (
        <LI key={form.id}>
          <A to={`${form.id}`}>{form.meta.name}</A>
        </LI>
      ))}
    </UL>
  </Wrapper>
)
