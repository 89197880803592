import React from 'react'

import Textarea from '../../ui/textarea'

export const Edit = ({ config, value, onChange }) => (
  <Textarea
    placeholder={config.placeholder}
    value={value}
    onChange={onChange}
  />
)
